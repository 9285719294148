.ReactTable{
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    background-color: transparent;
  }
  .ReactTable .actions-right{    
    text-align: right;
  }
  .ReactTable .actions-left{
    text-align: left;
  }
  .ReactTable .actions-center{
    text-align: center;
  }
  .ReactTable *{
    box-sizing:border-box
  }
  .ReactTable .rt-table{
    -webkit-box-flex:1;
    -ms-flex:auto 1;
    flex:auto 1;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:stretch;
    -ms-flex-align:stretch;
    align-items:stretch;
    width:100%;
    border-collapse:collapse;
    overflow:auto;
  }
  .ReactTable .rt-thead{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background: #95989A;
  }

  .ReactTable .rt-thead.-filters{
    display: none;
    /*visibility: hidden;
    height: 0;*/
  }

  .ReactTable .rt-thead.-headerGroups{
    background:rgba(0,0,0,0.03);
    border-bottom:1px solid rgba(0,0,0,0.05)
  }
  .ReactTable .rt-thead.-filters{
    border-bottom:1px solid rgba(0,0,0,0.05);
  }
  .ReactTable .rt-thead.-filters input,.ReactTable .rt-thead.-filters select{
    border:1px solid rgba(0,0,0,0.1);
    background: #95989A;
    padding:5px 7px;
    font-size:inherit;
    border-radius:3px;
    font-weight:normal;
    outline:none
  }
  .ReactTable .rt-thead .rt-tr{
    text-align:center
  }
  .ReactTable .rt-thead .rt-th:last-child{
    text-align: center;
  }
  /*.ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after{
      opacity: 0.4;
      content: "\F0DC";
      font-weight: bold;
      position: relative;
      display: inline-block;
      bottom: 0px;
      right: -7px;
      font-family: 'Larsseit',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"';    
  }
  .ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-desc > div:first-of-type:after{
      content: "\F0DD";
      top: -3px;
      font-weight: bold;
      position: relative;
      display: inline-block;
      bottom: 0px;
      right: -7px;
      font-family: Font Awesome\ 5 Free;
      opacity: 0.8;
  }
  .ReactTable .rt-thead .rt-th.-cursor-pointer.-sort-asc > div:first-of-type:after{
      content: "\F0DE";
      top: 3px;
      font-weight: bold;
      position: relative;
      display: inline-block;
      bottom: 0px;
      right: -7px;
      font-family: Font Awesome\ 5 Free;
      opacity: 0.8;
  }*/
  .ReactTable .rt-thead .rt-th,.ReactTable .rt-thead .rt-td{
    font-size: 1.063rem;
    text-align: left;
    font-weight: 300;
    /* line-height: 2.5em !important; */
    padding: 5px;
    line-height:normal;
    position:relative;
    transition:box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
    box-shadow:inset 0 0 0 0 transparent;
  }
  .ReactTable .rt-thead .rt-th.-cursor-pointer,.ReactTable .rt-thead .rt-td.-cursor-pointer{
    cursor:pointer
  }
  .ReactTable .rt-thead .rt-th:last-child,.ReactTable .rt-thead .rt-td:last-child{
    border-right:0
  }
  .ReactTable .rt-thead .rt-resizable-header{
    overflow:visible;
  }
  .ReactTable .rt-thead .rt-resizable-header:last-child{
    overflow:hidden
  }
  .ReactTable .rt-thead .rt-resizable-header-content{
    overflow:hidden;
    text-overflow:ellipsis
  }
  .ReactTable .rt-thead .rt-header-pivot{
    border-right-color:#f7f7f7
  }
  .ReactTable .rt-thead .rt-header-pivot:after,.ReactTable .rt-thead .rt-header-pivot:before{
    left:100%;
    top:50%;
    border:solid transparent;
    /*content:" ";*/
    height:0;
    width:0;
    position:absolute;
    pointer-events:none
  }
  .ReactTable .rt-thead .rt-header-pivot:after{
    border-color:rgba(255,255,255,0);
    border-left-color:#fff;
    border-width:8px;
    margin-top:-8px
  }
  .ReactTable .rt-thead .rt-header-pivot:before{
    border-color:rgba(102,102,102,0);
    border-left-color:#f7f7f7;
    border-width:10px;
    margin-top:-10px
  }
  .ReactTable .rt-tbody{
    -webkit-box-flex:99999;
    -ms-flex:99999 1 auto;
    flex:99999 1 auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    background-color: transparent;
    /*overflow:overlay;*/
  }

  .ReactTable .rt-tbody .rt-tr-group:first-child{
    margin-top: 5px;
  }
  
  .ReactTable .rt-tbody .rt-tr-group{
    /*border:solid 1px rgba(0,0,0,0.1);*/
    margin-bottom: 2px;
    /*background-color: #fff;*/
  }

  .ReactTable .rt-tbody .rt-tr-group .rt-tr{
    border:solid 1px rgba(0,0,0,0.1);
    background-color: #fff;
  }

  .ReactTable .rt-tbody .rt-tr-group .rt-tr.-padRow{
    /*visibility: hidden;*/
    border: none;
    background-color: transparent;

  }

  .ReactTable .rt-tbody .rt-tr-group:last-child{    
    margin-bottom: 3px;
  }
  .ReactTable .rt-tbody .rt-td:last-child{
    border-right:0
  }
  .ReactTable .rt-tbody .rt-expandable{
    cursor:pointer
  }
  .ReactTable .rt-tr-group{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:stretch;
    -ms-flex-align:stretch;
    align-items:stretch
  }
  .ReactTable .rt-tr{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    
  }
  .ReactTable .rt-th,.ReactTable .rt-td{
    
    -webkit-box-flex:1;
    -ms-flex:1 0 0px;
    flex:1 0 0;
    white-space:wrap;
    text-overflow:ellipsis;
    padding:5px;
    overflow:hidden;
    transition:.3s ease;
    transition-property:width,min-width,padding,opacity;
    margin: auto;
  }

  .ReactTable .rt-th.-hidden,.ReactTable .rt-td.-hidden{
    width:0 !important;
    min-width:0 !important;
    padding:0 !important;
    border:0 !important;
    opacity:0 !important
  }
  .ReactTable .rt-expander{
    display:inline-block;
    position:relative;
    margin:0;
    color:transparent;
    margin:0 10px;
  }
  .ReactTable .rt-expander:after{
    /*content:'';*/
    position:absolute;
    width:0;
    height:0;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%,-50%) rotate(-90deg);
    transform:translate(-50%,-50%) rotate(-90deg);
    border-left:5.04px solid transparent;
    border-right:5.04px solid transparent;
    border-top:7px solid rgba(0,0,0,0.8);
    transition:all .3s cubic-bezier(.175,.885,.32,1.275);
    cursor:pointer
  }
  .ReactTable .rt-expander.-open:after{
    -webkit-transform:translate(-50%,-50%) rotate(0);
    transform:translate(-50%,-50%) rotate(0)
  }
  .ReactTable .rt-resizer{
    display:inline-block;
    position:absolute;
    width:36px;
    top:0;
    bottom:0;
    right:-18px;
    cursor:col-resize;
    z-index:10
  }
  .ReactTable .rt-tfoot{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    box-shadow:0 0 15px 0 rgba(0,0,0,0.15);
    background-color: #686B6D;
    color: #fff;
  }
  .ReactTable .rt-tfoot .rt-td{
    border-right:1px solid rgba(0,0,0,0.05);
    color: #fff !important;
  }
  .ReactTable .rt-tfoot .rt-td:last-child{
    border-right:0
  }
  .ReactTable.-striped .rt-tr.-odd{
    background:rgba(0,0,0,0.03)
  }
  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover{
    /*background:rgba(0,0,0,0.05)*/
  }
  .ReactTable .pagination-bottom{
    background-color: #E5E6E6
  }
  .ReactTable .-pagination{
    z-index:1;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:stretch;
    -ms-flex-align:stretch;
    align-items:stretch;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    padding:3px;
  }
  .ReactTable .rt-thead.-filters select, .ReactTable .-pagination .select-wrap select{
    border: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#9c27b0), to(#9c27b0)), -webkit-gradient(linear, left top, left bottom, from(#D2D2D2), to(#D2D2D2));
    background-image: -webkit-linear-gradient(#9c27b0, #9c27b0), -webkit-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: -o-linear-gradient(#9c27b0, #9c27b0), -o-linear-gradient(#D2D2D2, #D2D2D2);
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    -webkit-transition: background 0s ease-out;
    -o-transition: background 0s ease-out;
    transition: background 0s ease-out;
    float: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    height: 36px;
  
  }
  .ReactTable .rt-thead.-filters select, .ReactTable .-pagination .select-wrap select:focus{
    outline: none;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
  }
  .ReactTable .-pagination input,.ReactTable .-pagination select{
    border:1px solid rgba(0,0,0,0.1);
    background:#fff;
    padding:5px 7px;
    font-size:inherit;
    border-radius:3px;
    font-weight:normal;
    outline:none
  }
  .ReactTable .-pagination .-btn{
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    display:block;
    width:100%;
    height:100%;
    border:0;
    border-radius:3px;
    padding:6px;
    font-size:1em;
    color:rgba(0,0,0,0.6);
    background:rgba(0,0,0,0.1);
    transition:all .1s ease;
    cursor:pointer;
    outline:none;
  }
  .ReactTable .-pagination .-btn[disabled]{
    opacity:.5;
    cursor:not-allowed;
  }
  .ReactTable .-pagination .-btn:not([disabled]):hover{
    background:rgba(0,0,0,0.3);
    color:#fff
  }
  .ReactTable .-pagination .-previous,.ReactTable .-pagination .-next{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    text-align:center
  }
  .ReactTable .-pagination .-center{
    -webkit-box-flex:1.5;
    -ms-flex:1.5;
    flex:1.5;
    text-align:center;
    margin-bottom:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-direction:row;
    flex-direction:row;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-pack:distribute;
    justify-content:space-around
  }
  .ReactTable .-pagination .-pageInfo{
    display:inline-block;
    margin:3px 10px;
    white-space:nowrap
  }
  .ReactTable .-pagination .-pageJump{
    display:inline-block;
  }
  .ReactTable .rt-thead.-filters input, .ReactTable .-pagination .-pageJump input{
    width:70px;
    text-align:center;
    border: 0;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: rgba(0, 0, 0, 0);
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    width: 100%;
    height: 36px;
    padding: 7px 0;
    font-size: 14px;
    line-height: 1.428571429;
    display: block;
    color: #555;
  }
  .ReactTable .rt-thead.-filters input, .ReactTable .-pagination .-pageJump input:focus {
    outline: none;
    background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
    background-size: 100% 2px, 100% 1px;
    box-shadow: none;
    transition-duration: 0.3s;
  }
  .ReactTable .-pagination .-pageSizeOptions{
    margin:3px 10px
  }
  .ReactTable .rt-noData{
    display:block;
    position:absolute;
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background:rgba(255,255,255,0.8);
    transition:all .3s ease;
    z-index:1;
    pointer-events:none;
    padding:20px;
    color:rgba(0,0,0,0.5)
  }
  .ReactTable .-loading{
    display:block;
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background:rgba(255,255,255,0.8);
    transition:all .3s ease;
    z-index:-1;
    opacity:0;
    pointer-events:none;
  }
  .ReactTable .-loading > div{
    position:absolute;
    display:block;
    text-align:center;
    width:100%;
    top:25%;
    left:0;
    font-size:15px;
    color:rgba(0,0,0,0.6);
    -webkit-transform:translateY(-52%);
    transform:translateY(-52%);
    transition:all .3s cubic-bezier(.25,.46,.45,.94)
  }
  .ReactTable .-loading.-active{
    opacity:1;
    z-index:2;
    pointer-events:all;
  }
  .ReactTable .-loading.-active > div{
    -webkit-transform:translateY(50%);
    transform:translateY(50%)
  }
  .ReactTable .rt-resizing .rt-th,.ReactTable .rt-resizing .rt-td{
    transition:none !important;
    cursor:col-resize;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
  }
  .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td input {
      text-align: left;
  }
  .wordwrap {
    white-space: pre-line !important;
    word-wrap: break-word;
    text-align: top !important;
  }

  .rt-tr {
    height: max-content !important;
  }