#InternalHeader{
  width: 100%;
  height: 32px;
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  .info-page{
    .title {
      font-size: 16px;
      line-height: 17px;
      color: #2E2A25;
    }
  }
  .buttons{
    display: flex;
    justify-content: flex-end;
    width: 40%;
    .btn-internal-header{
      flex-direction: initial;
      text-transform: capitalize;
      font-size: 11px;
      font-style: normal;
      font-weight: normal;
    }
    .btn-internal-header:first-child{
      margin-right: 20px;
    }
  }
}