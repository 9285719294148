@import "reactTableCustomStyle.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

html{
    font-size: 14px;
    font-family: Larsseit,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

body {
    margin: 0;
    padding: 0;
    background-color: #F9F9F9;
    min-width: 1024px;
}
.MuiFormLabel-root.Mui-focused{
    color:#82bc00 !important;
}
.MuiInput-underline:before{
    border-bottom: 1px solid #82BC00 !important;
}
.MuiInput-underline:after {
    border-bottom: 1px solid #82BC00 !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}
#pdfViewer .header-pdf {
  height: 50px;
  position: relative;
  background-color: rgb(50, 54, 57);
  color: azure;
}
#pdfViewer .vertical-center {
  position: absolute;
  top: 50%;
  margin-left: 20px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); 
  }
  #pdfViewer .icons {
    height: 50px;
    color: white;
}
#carousel::-webkit-scrollbar {
    display: none;
    width: 100%;
}
.arrow-carousel:focus {
    outline: none;
}
#atencion-cliente p {
    text-align: center;
}
#atencion-cliente a:link{
    text-decoration: none;
    color: rgb(2, 2, 2);
}
#atencion-cliente a:visited{
    color: rgb(2, 2, 2);
    text-decoration: none;  
}
#atencion-cliente p{
    color: rgb(2, 2, 2);
}
/*Negocios*/
#seguros-wrap {
    width: 1000px;
    padding: 0px 0px 0px 0px;
    margin: 0px auto;
}
#bajada {
    /*padding: 0px 0px 30px 0px;*/
    border-bottom: 1px solid #c7c7c7;
}
 #textos {
    margin: 0px;
    padding-top: 25px;
    padding-bottom: 50px;
}
.column_tres {
    width: 79%;
    border-right-width: 1px;
    border-right-style: dotted;
    border-right-color: #414141;
    padding-left: 18px;
    /* height: 350px; */
    padding-right: 20px;
}
#textos .col {
    width: 475px;
    float: left;
    margin-right: 50px;
}
#textos .col:nth-child(2n) {
    margin-right: 0px !important;
}
.dv-contact-Negocio-box {
    width: 205px;
    height: 50px;
    margin: 179px 0 0 0;
    border: 1px solid rgba(0, 0, 0, .2);
}
.dv-contact-Negocio-box-vendor {
    width: 205px;
    height: 50px;
    margin: 29px 0 0 0;
    border: 1px solid rgba(0, 0, 0, .2);
}
.dv-contact-Negocio-box-vendor a:link {
   color: white;
    
}
.dv-contact-Negocio-box-vendor a:visited {
    color: white;
     
 }
.greenFactotal {
    background: #98DC01;
  }
  .dv-contact-Negocio-box a:link {
   color: white;
}
.dv-contact-Negocio-box a:visited {
    color: white;
}
.dv-contact-Negocio-box-seguro {
    width: 205px;
    height: 50px;
    /* margin: 235px 0 0 0; */
    border: 1px solid rgba(0, 0, 0, .2);
    /* position: absolute; */
}
.dv-contact-Negocio-box-seguro a:link {
   color: white;
    
}
.dv-contact-Negocio-box-seguro a:visited {
    color: white;
     
 }
/*--------------- END MEDIA QUERIES ------------*/
/* custom */
.container{
    width: 96.66%;
}
.paginatorFTC{
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination-container{
    background-color: #E5E6E6;
    height: 50px;
    padding: 0px 0px 0px 0px !important;
    font-size: 11px;
}
.paginatorIcon {
    width: 30px !important;
    height: 30px !important;
    background-color: #fff !important;
    border: 1px solid #82BC00 !important;
    margin-right: 4px !important;
    margin-left: 4px !important;
}

/* hover */
.hoverRow:hover {
    color: #fff;
}

.activeLink {
    font-weight: bold !important;
}
@media (min-width: 960px) {
    .jss2.jss7 {
        width: calc(100% - 210px) !important;
    }
    .jss215.jss218>.jss2 {
        width: 100% !important;
    }
}