/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Larsseit';
    src: url('Larsseit-Bold.eot');
	src: url('Larsseit-Bold.eot?#iefix') format('embedded-opentype'),
		url('Larsseit-Bold.woff2') format('woff2'),
		url('Larsseit-Bold.woff') format('woff'),
		url('Larsseit-Bold.ttf') format('truetype'),
		url('Larsseit-Bold.otf') format('opentype'),
		url('Larsseit-Bold.svg#Larsseit-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Larsseit';
    src: url('Larsseit-Italic.eot');
	src: url('Larsseit-Italic.eot?#iefix') format('embedded-opentype'),
		url('Larsseit-Italic.woff2') format('woff2'),
		url('Larsseit-Italic.woff') format('woff'),
		url('Larsseit-Italic.ttf') format('truetype'),
		url('Larsseit-Italic.otf') format('opentype'),
		url('Larsseit-Italic.svg#Larsseit-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}

@font-face {
    font-family:'Larsseit';
    src: url('Larsseit.eot');
	src: url('Larsseit.eot?#iefix') format('embedded-opentype'),
		url('Larsseit.woff2') format('woff2'),
		url('Larsseit.woff') format('woff'),
		url('Larsseit.ttf') format('truetype'),
		url('Larsseit.otf') format('opentype'),
		url('Larsseit.svg#Larsseit') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}