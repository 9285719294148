@charset "UTF-8";
// @import './node_modules/materialize-css/sass/components/color-variables';
// @import './node_modules/materialize-css/sass/components/color-classes';
// @import './node_modules/materialize-css/sass/components/variables';
// @import './node_modules/materialize-css/sass/components/normalize';
// @import './node_modules/materialize-css/sass/components/global';
// @import './node_modules/materialize-css/sass/components/icons-material-design';
// @import './node_modules/materialize-css/sass/components/grid';
// @import './node_modules/materialize-css/sass/components/typography';
// @import './node_modules/materialize-css/sass/components/transitions';
// @import './node_modules/materialize-css/sass/components/cards';
// @import './node_modules/materialize-css/sass/components/tabs';
// @import './node_modules/materialize-css/sass/components/buttons';
// @import './node_modules/materialize-css/sass/components/waves';
// @import './node_modules/materialize-css/sass/components/modal';
// @import './node_modules/materialize-css/sass/components/collapsible';
// @import './node_modules/materialize-css/sass/components/pulse';
// @import './node_modules/materialize-css/sass/components/datepicker';
// @import "components/badges";
// @import "components/navbar";
// @import "components/toast";
// @import "components/tooltip";
// @import "components/dropdown";
// @import "components/chips";
// @import "components/materialbox";
// @import "components/forms/forms";
// @import "components/forms/forms/input-fields.scss";
// @import "components/table_of_contents";
// @import "components/sidenav";
// @import "components/preloader";
// @import "components/slider";
// @import "components/carousel";
// @import "components/tapTarget";
// @import "components/timepicker";
// @import "./scss/_textinput.scss";
// @import "./scss/_button.scss";
// @import "./scss/_card.scss";
// @import "./scss/_forms.scss";
@import "./scss/_header.scss";
@import "./scss/_breadcrumb.scss";


/* Fixed */
.row.no-padding {
  .col{
    padding: 0px;
  }
  .input-field {
    padding: 0px 16px 0px 0px;
  }
}
/* custom variables */
:root {
  --primary-color: #82bc00;
  --primary-color-contrast: #ffffff;
  --placeholder: #555759;
  --icon-input-color: #BDBFC0;
  --disabled: rgba(130, 188, 0, 0.26);
}

/* detalle proceso */
#detalle-proceso{
  //margin-bottom: 110px;
  .cuadro-dos{
    width: 100%;
    position: relative;
    font-family: 'Arial';
    font-style: normal;
    font-weight: normal;
    h5{
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      margin-bottom: 0px;
      color: #3C3C3C;
    }
    p{
      font-size: 12px;
      line-height: 20px;
      color: #3C3C3C;
      margin-bottom: 10px;
    }
    .inputPass{
      margin-top: 0px;
      input{
        padding: 13.5px 14px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .radioButtons{
      display: block;
      font-size: 12px;
      line-height: 14px;
      color: #8C8C8C;
      label{
        margin-left: -2px;
        margin-bottom: 8px;
        span{
          span:first-child{
            margin-right: 10px;
            svg {
              color: rgba(130, 188, 0, .3) !important;
              &:checked {
                color: rgba(130, 188, 0, 1) !important;
              }
            }
            
          }
        }
      }
      .labelRadio{
        span{
          color: #3C3C3C;
        }
      }
    }
  }
}
.tableLastRowAction{
  tbody{
    tr{
      td:last-child{
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #82BC00;
      }
    }
  }
  thead{
    tr{
      th:last-child{
        text-align: right;
      }
    }
  }
}
.banner-header{
  width: 100%;
  height: 60px;
  padding: 11px 63px 9px 26px;
  box-sizing: border-box;
  background: rgba(130, 188, 0, 0.1);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg{
    margin-right: 17px;
  }
  span{
    margin: 0px;
    font-size: 14px;
    color: #6F777B;
    font-style: normal;
    font-weight: 500;
  }
}
